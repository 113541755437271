// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  staging: false,
  qa: false,
  dev: false,
  local: false,
  TOKEN_KEY: 'sb-jrcwckvyaujjgtlveoug-auth-token',
  API_URL: 'https://vision-api.prod.api.alternative-point.com',
  APP_URL: 'https://vision-app.alternative-point.com',
  SUPABASE: {
    SUPABASE_URL: 'https://jrcwckvyaujjgtlveoug.supabase.co',
    // eslint-disable-next-line max-len
    SUPABASE_KEY:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpyY3dja3Z5YXVqamd0bHZlb3VnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTgxMDA4NDUsImV4cCI6MjAzMzY3Njg0NX0.rY4uCELwEbe8GCmWF_Vp9F7qD34lySG2TGMvJ0LqS4c',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
